<style lang="scss">
	@font-face {
		font-family: "Leixo";
		src: url("./fonts/LEIXO-DEMO.ttf");
	}
	@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
	body {
		width: 100%;
		padding-bottom: env(safe-area-inset-bottom);
	}
	html,
	body,
	div,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	ul,
	ol,
	li,
	dl,
	dt,
	dd,
	form,
	fieldset,
	legend,
	input,
	textarea,
	button,
	table,
	th,
	td {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	::selection {
	}
	/* For Mozilla Firefox */
	::-moz-selection {
	}
	.server-error {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1000000000;
		background-color: rgb(255, 255, 255);
	}
	.loader{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1000000000;
		background-color: rgb(255, 255, 255);
		display: flex;
		justify-content: center;
		align-items: center;
		.spinner{
			width: 100px;
			height: 100px;
			border-radius: 50%;
			border: 5px solid #f3f3f3;
			border-top: 5px solid #3498db;
			animation: spin 1s linear infinite;
			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}
	}
</style>
<template>
	<div id="app">
		<div class="loader" v-if="loader">
			<div class="spinner"></div>
		</div>
		<div class="server-error" v-if="error != undefined">
			<div class="error__content">
				<p>{{ error.message }}</p>
				<p>sry but, server is Prolly off</p>
			</div>
		</div>
		<div class="main" v-else>
			<router-view />
		</div>
	</div>
</template>
<script>
	import axios from "axios";
	export default {
		data() {
			return {
			};
		},
		mounted() {
			console.log(process.env.VUE_APP_SUPER)
			this.$store.commit("setLoader", true);
			axios
				.get("/")
				.then((response) => {
					if (response.status == 200) {
						setTimeout(() => {
							this.$store.commit("setLoader", false);
						}, 400);
					}
				})
				.catch((error) => {
					if (error.response.status == 0) {
						this.$store.dispatch("setUser", undefined);
						this.$store.commit("setError", error);
						setTimeout(() => {
							this.$store.commit("setLoader", false);
						}, 400);
					}
				});
			if (this.$store.state.user == undefined) {
				if (this.$route.path != "/") {
					this.$router.push("/");
				}
			} else {
				if (this.$route.path == "/") {
					this.$router.push("/me");
				}
			}
		},
		beforeMount() {
			if (this.$store.state.user != undefined) {
				axios
					.get("/user/self")
					.then((response) => {
						if (response.status === 200) {
							this.$store.dispatch("setUser", response.data.user);
							if (this.$route.path == "/") {
								this.$router.push("/me");
							}
						}
						if (response.status === 403 || response.status === 401) {
							this.$store.dispatch("setUser", undefined);
							if (this.$route.path != "/") {
								this.$router.push("/");
							}
						}
					})
					.catch((error) => {
						if (error.response.status == 403 || error.response.status == 401) {
							this.$store.dispatch("setUser", undefined);
							this.$store.commit("setVpnWarning", true);
							if (this.$route.path != "/") {
								this.$router.push("/");
							}
						}
						if (error.response.status == 0) {
							this.$store.dispatch("setUser", undefined);
							this.$store.commit("setError", error);
						}
						console.log(error);
					});
			}
		},
		computed: {
			error() {
				return this.$store.state.error;
			},
			loader() {
				return this.$store.state.loader;
			},
		},
	};
</script>
