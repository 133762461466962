import Vue from 'vue'
import Vuex from 'vuex'
import VuePersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuePersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
    vpnWarning: state.vpnWarning
  })
})

export default new Vuex.Store({
  state: {
    user: undefined,
    smartHomeEdit: false,
    vpnWarning: true,
    error: undefined,
    loader: false
  },
  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setSmartHomeEdit(state, smartHomeEdit) {
      state.smartHomeEdit = smartHomeEdit
    },
    setVpnWarning(state, vpnWarning) {
      state.vpnWarning = vpnWarning
    },
    setError(state, error) {
      state.error = error
    },
    setLoader(state, loader) {
      state.loader = loader
    },
  },
  actions: {
    setUser(context, user) {
      context.commit('setUser', user)
    },
    getDevices(){
      if(navigator.bluetooth){
        navigator.bluetooth.requestDevice({
          filters: [{
            name: 'ESP32'
          }]
        })
        .then(device => {
          console.log(device)
        })
        .catch(error => {
          console.log(error)
        })
      }
    }
  },
  modules: {
  },
  plugins: [
    vuexLocal.plugin
  ]
})
