<style lang="scss" scoped>
	.my-smart-home {
		width: 100%;
		padding: 10px;
		margin-left: 0;
		nav {
			padding: 5px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			h1 {
				font-size: 30px;
				font-family: "Poppins", sans-serif;
				font-weight: 600;
				-webkit-touch-callout: none; /* iOS Safari */
				-webkit-user-select: none; /* Safari */
				-khtml-user-select: none; /* Konqueror HTML */
				-moz-user-select: none; /* Old versions of Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
				user-select: none; /* Non-prefixed version, currently
																								                                  supported by Chrome, Edge, Opera and Firefox */
			}
		}
		.device-controller {
			width: 80%;
			margin-left: auto;
			margin-right: auto;
			font-family: "Poppins", sans-serif;
			font-size: 20px;
			font-weight: 600;
		}
	}
</style>
<template>
	<div class="my-smart-home" v-if="smarthome">
		<div class="popup" :class="{active: popupActive}"></div>
		<nav>
			<h1>{{ smarthome.name }}</h1>
		</nav>
		<div class="device-controller">
			<carousel :devices="smarthome.devices" :interval="5000"></carousel>
		</div>
	</div>
</template>
<script>
	import carousel from '@/components/carousel.vue';
	import axios from "axios";
	export default {
		data() {
			return {
				smarthome: undefined,
				hue: 0,
				brightness: 0,
				popupActive: true,
			};
		},
		components: {
			carousel,
		},
		mounted() {
			if (this.$store.state.user == undefined) {
				this.$router.push("/");
			}
			axios
				.get("/smartHome/" + this.$route.params.id)
				.then((response) => {
					console.log(response);
					this.smarthome = response.data.smartHomeResponse;
				})
				.catch((error) => {
					if (error.response.status == 403 || error.response.status == 401) {
						this.$store.dispatch("setUser", undefined);
						this.$router.push("/");
					}
					if (error.response.status == 0) {
						this.$store.dispatch("setUser", undefined);
						this.$store.commit("setError", error);
					}
					console.log(error);
				});
		},
		methods: {
		},

	};
</script>