<style lang="scss" scoped>
	.me {
		.warning {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100vh;
				background-color: rgba(0, 0, 0, 0.5);
			}
			.modal {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 250px;
				background-color: #ffffff;
				border-radius: 15px;
				box-shadow: #4949491c 0px 0px 10px;
				padding: 20px;
				.modal__container {
					.modal-close-button__wrap {
						display: flex;
						justify-content: flex-end;
						font-size: 18px;
					}
					.modal-content {
						display: flex;
						justify-content: center;
						p {
							font-family: "Poppins", sans-serif;
							font-size: 20px;
							text-align: center;
							font-weight: 500;
							span {
								font-weight: 600;
								color: red;
							}
						}
					}
				}
			}
		}
		display: flex;
		.side-nav {
			top: 0;
			width: 0;
			height: 100vh;
			position: fixed;
			z-index: 1;
			padding-bottom: env(safe-area-inset-bottom);
			overflow-x: hidden;
			overflow-y: hidden;
			left: 0;
			bottom: 0;
			background-color: #f5f5f5;
			overflow-x: hidden;
			margin-left: 0;
			transition: width 0.3s ease;
			box-shadow: inset -7px 0 15px -7px rgba(0, 0, 0, 0.158);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.side-nav__wrap {
				width: 65%;
				margin-top:93px;
				.side-nav-back-button__container {
					display: flex;
					.side-nav-back__button {
						height: 40px;
						display: flex;
						align-items: center;
						font-size: 20px;
						cursor: pointer;
					}
				}
				.side-nav-toggle-smarthome-add__input {
					display: flex;
					justify-content: center;
					height: 40px;
					margin-bottom: 20px;
					margin-top: 23px;
					background-color: white;
					border-radius: 7px;
					border: solid 1px #494949;
					input {
						width: 100%;
						font-size: 16px;
						padding: 7px;
						outline: none;
						background: 0;
						border: 0;
					}
					input::placeholder {
						font-family: "Poppins", sans-serif;
					}
				}
				@keyframes shake {
					0% {
						transform: translateX(0);
					}
					25% {
						transform: translateX(-5px);
					}
					50% {
						transform: translateX(5px);
					}
					75% {
						transform: translateX(-5px);
					}
					100% {
						transform: translateX(0);
					}
				}
				.side-nav-toggle-smarthome-add__input.error {
					border: solid 1px #ff0000;
				}
				.side-nav-toggle-smarthome-add__input.shake {
					animation: shake 0.3s;
				}
				.side-nav-button__container {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 20px;
					> div {
						width: 40px;
						height: 40px;
						border-radius: 10px;
						font-size: 16px;
						display: flex;
						justify-content: center;
						align-items: center;
						border: 0;
						outline: none;
						color: #ffffff;
						background-color: #444444;
						cursor: pointer;
						transition: 0.3s;
					}
				}
				.smart-home-add {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 10px;
					.input {
						width: 80%;
						font-size: 16px;
						padding: 7px;
						outline: none;
						border-radius: 7px;
						border: solid 1px #494949;
					}
					input::placeholder {
						font-family: "Poppins", sans-serif;
					}

					button {
						width: 50px;
						height: 50px;
						border-radius: 10px;
						font-size: 22px;
						display: flex;
						justify-content: center;
						align-items: center;
						border: 0;
						outline: none;
						color: #ffffff;
						background-color: #424ef7;
						cursor: pointer;
						box-shadow: #232fd15e 0px 5px 10px;
						transition: 0.3s;
					}
				}
			}
			.side-nav__wrap.openAddActive{
				margin-top:10px;
			}
			.side-nav-smarthome-logout__button {
				width: 40px;
				height: 40px;
				border-radius: 10px;
				font-size: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0;
				outline: none;
				color: #ffffff;
				background-color: #444444;
				cursor: pointer;
				transition: 0.3s;
				margin-bottom: 20px;
			}
		}
		.side-nav.sideNavActive {
			width: 65px;
			transition: width 0.3s ease;
		}
		.side-nav.openAddActive {
			padding-top: 20px;
			width: 100%;
			transition: width 0.3s ease;
		}
		.smart-home {
			width: 100%;
			padding: 10px;
			margin-left: 0;
			transition: width, margin-left 0.3s ease;
			nav {
				padding: 5px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				h1 {
					font-size: 32px;
					font-family: "Leixo", sans-serif;
					font-weight: 600;
					-webkit-touch-callout: none; /* iOS Safari */
					-webkit-user-select: none; /* Safari */
					-khtml-user-select: none; /* Konqueror HTML */
					-moz-user-select: none; /* Old versions of Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
					user-select: none; /* Non-prefixed version, currently
																									                                  supported by Chrome, Edge, Opera and Firefox */
				}
				.side-nav__button {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 5px;
					border-radius: 10px;
					color: #000000;
					font-size: 25px;
					cursor: pointer;
				}
			}
			.smart-home__container {
				margin-top: 20px;
				padding: 0 10px;
				.smart-home__wrap {
					display: flex;
					flex-direction: column;
					gap: 20px;
				}
			}
		}
		.smart-home.sideNavActive {
			margin-left: 75px;
			transition: 0.3s ease;
		}
		.smart-home.openAddActive {
			width: 0;
			margin-left: 0;
			transition: 0.3s ease;
		}
	}
</style>
<template>
	<div class="me" v-if="this.$store.state.user != undefined">
		<div class="warning" v-if="this.$store.state.vpnWarning">
			<div class="overlay"></div>
			<div class="modal">
				<div class="modal__container">
					<div class="modal-close-button__wrap">
						<div class="modal-close__button" @click="closeVPN()">
							<font-awesome-icon icon="fa-solid fa-xmark" />
						</div>
					</div>
					<div class="modal-content">
						<p>
							Při používání <span>VPN</span> nemusí aplikace fungovat správně!
						</p>
					</div>
				</div>
			</div>
		</div>
		<div
			class="side-nav"
			:class="{ sideNavActive: sidenav, openAddActive: openAddSmartHome }"
		>
			<div class="side-nav__wrap" :class="{ openAddActive: openAddSmartHome}">
				<div class="side-nav-back-button__container" :style=" openAddSmartHome">
					<div
						class="side-nav-back__button"
						v-show="openAddSmartHome"
						@click="
							(openAddSmartHome = false),
								(shake = false),
								(smartHomeNameError = false),
								(smartHomeName = '')
						"
					>
						<font-awesome-icon icon="fa-solid fa-arrow-left" />
					</div>
				</div>
				<div
					class="side-nav-toggle-smarthome-add__input"
					v-show="openAddSmartHome"
					:class="{ error: smartHomeNameError, shake: shake }"
				>
					<input
						type="text"
						v-model="smartHomeName"
						placeholder="Name"
						class="input"
					/>
				</div>
				<div class="side-nav-button__container">
					<div
						class="side-nav-profile__button"
						v-show="openAddSmartHome == false"
					>
						<font-awesome-icon icon="fa-regular fa-user" />
					</div>
					<div
						class="side-nav-smarthome-add__button"
						@click="
							openAddSmartHome ? addNewSmartHome() : (openAddSmartHome = true)
						"
					>
						<font-awesome-icon icon="fa-solid fa-plus" />
					</div>
				</div>
			</div>
			<div
				class="side-nav-smarthome-logout__button"
				v-show="openAddSmartHome == false"
				@click="logout()"
			>
				<font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
			</div>
		</div>
		<div
			class="smart-home"
			:class="{ sideNavActive: sidenav, openAddActive: openAddSmartHome }"
		>
			<nav>
				<h1>QIMP</h1>
				<div class="side-nav__button" @click="sidenav = !sidenav">
					<font-awesome-icon icon="fa-solid fa-bars-staggered" />
				</div>
			</nav>
			<div class="smart-home__container">
				<div class="smart-home__wrap" v-if="user">
					<template v-for="(smartHome, index) in user.smartHome">
						<smart-home-box
							:smartHome="smartHome"
							:index="index"
							:key="smartHome._id"
						/>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import smartHomeBox from "@/components/smartHomeBox.vue";
	import axios from "axios";
	export default {
		data() {
			return {
				sidenav: false,
				openAddSmartHome: false,
				smartHomeName: "",
				smartHomeNameError: false,
				shake: false,
			};
		},
		components: {
			smartHomeBox,
		},
		computed: {
			user() {
				return this.$store.state.user;
			},
		},
		methods: {
			addNewSmartHome() {
				if (this.smartHomeName.length > 0) {
					axios
						.post("/smartHome/new", {
							name: this.smartHomeName,
						})
						.then((response) => {
							if (response.status == 200) {
								this.smartHomeName = "";
								this.openAddSmartHome = false;
								this.shake = false;
								this.smartHomeNameError = false;
								this.$store.dispatch("setUser");
							}
						})
						.catch((error) => {
							if (error.response.status == 403 || error.response.status == 401) {
								this.$store.dispatch("setUser", undefined);
								this.$store.commit("setVpnWarning", true);
								this.$router.push("/");
							}
							if (error.response.status == 0) {
								this.$store.dispatch("setUser", undefined);
								this.$store.commit("setError", error);
							}
						});
				} else {
					this.smartHomeNameError = true;
					if (!this.shake) {
						this.shake = true;
						setTimeout(() => {
							this.shake = false;
						}, 500);
					}
				}
			},
			closeVPN() {
				this.$store.commit("setVpnWarning", false);
			},
			logout() {
				axios
					.delete("/logout")
					.then((response) => {
						console.log(response);
						if (response.status == 200) {
							this.$store.dispatch("setUser", undefined);
							this.$router.push("/");
						}
					})
					.catch((error) => {
						if (error.response.status == 0) {
							this.$store.dispatch("setUser", undefined);
							this.$store.commit("setError", error);
						}
						console.log(error);
					});
			},
		},
		mounted() {
			if (this.$store.state.user == undefined) {
				this.$router.push("/");
			}
		},
	};
</script>