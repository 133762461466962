<style lang="scss" scoped>
	.landing {
		font-family: "Poppins", sans-serif;
		width: 100%;
		height: 100vh;
		background-color: #f5f5f5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.landing-content {
			.landing-login__box {
				width: 350px;
				padding: 50px 5px;
				background-color: #ffffff;
				display: flex;
				border-radius: 15px;
				flex-direction: column;
				gap: 30px;
				text-align: center;
				box-shadow: #4949491c 0px 0px 10px;
				h1 {
					font-family: "Leixo", sans-serif;
					font-size: 40px;
					font-weight: 700;
				}
				.landing-login__container form {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 20px;
					input {
						width: 80%;
						font-size: 16px;
						padding: 7px;
						outline: none;
						border-radius: 7px;
						border: solid 1px #494949;
					}
					input::placeholder {
						font-family: "Poppins", sans-serif;
					}
					button {
						margin-top: 10px;
						width: 50px;
						height: 50px;
						border-radius: 10px;
						font-size: 22px;
						display: flex;
						justify-content: center;
						align-items: center;
						border: 0;
						outline: none;
						color: #ffffff;
						background-color: #424ef7;
						cursor: pointer;
						box-shadow: #232fd15e 0px 5px 10px;
						transition: 0.3s;
					}
					button:hover {
						transition: 0.3s;
						box-shadow: #424ef7bb 0px 5px 10px;
					}
				}
			}
		}
	}
</style>
<template>
	<div class="landing">
		<div class="landing-content">
			<div class="landing-login__box">
				<h1>QIMP</h1>
				<div class="landing-login__container">
					<form v-on:keydown.enter.prevent="login()">
						<input type="email" placeholder="Email" v-model="email" />
						<input type="password" placeholder="Password" v-model="password" />
						<button type="button" @click="login()">
							<font-awesome-icon icon="fa-solid fa-arrow-right-to-bracket" />
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from "axios";
	export default {
		data() {
			return {
				email: "",
				password: "",
			};
		},
		methods: {
			login() {
				axios
					.post("/login", {
						email: this.email,
						password: this.password,
					})
					.then((response) => {
						if (response.status === 200) {
							this.$store.dispatch("setUser", response.data.user);
							//prevent prefilled data
							this.email = "";
							this.password = "";
							this.$router.push("/me");
						}
						this.password = "";
					})
					.catch((error) => {
						this.password = "";
						console.log(error);
						if (error.response.status == 0) {
							this.$store.dispatch("setUser", undefined);
							this.$store.commit("setError", error);
						}
					});
			},
		},
		mounted(){
			if(this.$store.state.user){
				this.$router.push("/me");
			}
		}
	};
</script>