<style lang="scss" scoped>
	.carousel-item {
		font-family: "Poppins", sans-serif;
		.icon-box {
			width: 100%;
			height: 350px;
			background-color: #27293f;
			border-radius: 15px;
		}
		p {
			font-size: 32px;
			margin-left: 10px;
		}
		.device-control {
			margin-top: 25px;
			display: flex;
			flex-direction: column;
			gap: 30px;
			background-color: #27293f;
			padding: 20px;
			border-radius: 15px;
			.brightness {
				input {
					width: 100%;
					height: 10px;
					-webkit-appearance: none;
					background-color: #f5f5f5;
					border-radius: 5px;
					outline: none;
					background: rgb(0, 0, 0);
					background: linear-gradient(
						90deg,
						rgba(0, 0, 0, 1) 0%,
						rgba(255, 255, 255, 1) 100%
					);
					&::-webkit-slider-thumb {
						-webkit-appearance: none;
						appearance: none;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background-color: #ffffff;
						cursor: pointer;
					}
				}
			}
			.hue {
				input {
					width: 100%;
					height: 10px;
					-webkit-appearance: none;
					background-color: #f5f5f5;
					border-radius: 5px;
					outline: none;
					background-color: rgba(0, 0, 0, 0.1);
					transition: color 0.05s linear;
					background: linear-gradient(
						to right,
						rgb(255, 0, 0),
						rgb(255, 255, 0),
						rgb(0, 255, 0),
						rgb(0, 255, 255),
						rgb(0, 0, 255),
						rgb(255, 0, 255),
						rgb(255, 0, 0)
					);
					&::-webkit-slider-thumb {
						-webkit-appearance: none;
						appearance: none;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background-color: #ffffff;
						cursor: pointer;
					}
				}
			}
		}
		.toggle-on-off {
			margin-top: 25px;
			button {
				width: 100%;
				height: 60px;
				border-radius: 15px;
				border: none;
				background-color: #27293f;
				color: #ffffff;
				font-size: 22px;
				font-weight: 600;
				cursor: pointer;
			}
			button.on {
				background-color: #2c4b36;
				color: #ffffff;
			}
		}
	}
	.carousel-item.disabled {
		filter: grayscale(80%);
	}
</style>
<template>
	<div class="carousel-item" :class="{ disabled: error }">
		<p>{{ device.deviceType.name }}</p>
		<div class="icon-box">
			<img :src="device.deviceType.icon" alt="" />
		</div>
		<div class="device-control">
			<div class="brightness">
				<input
					type="range"
					min="0"
					max="255"
					v-model="brightness"
					:disabled="error"
				/>
			</div>
			<div class="hue">
				<input type="range" min="0" max="255" v-model="hue" :disabled="error" />
			</div>
		</div>
		<div class="toggle-on-off">
			<button
				:class="{ on: toggle }"
				:disabled="error"
				@click="toggle = !toggle"
			>
				{{ toggle ? "On" : "Off" }}
			</button>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				hue: 0,
				brightness: 0,
				error: true,
				ws: undefined,
				toggle: false,
				firstload: false,
				secondload: false,
				thirdload: false,
			};
		},
		watch: {
			hue(OldValue, NewValue) {
				if (OldValue !== NewValue) {
					this.sendToWS();
				}
			},
			brightness(OldValue, NewValue) {
				if (OldValue !== NewValue) {
					this.sendToWS();
				}
			},
			toggle(OldValue, NewValue) {
				if (OldValue !== NewValue) {
					this.sendToWS();
				}
			},
		},
		props: ["device"],
		mounted() {
			this.connectToWS(this.device.localIP);
		},
		methods: {
			connectToWS(localIP) {
				try {
					this.ws = new WebSocket(`ws://${localIP}/ws`);
					this.ws.onopen = () => {
						this.error = false;
						console.log("Connected to WS");
					};
					this.ws.onmessage = (event) => {
						console.log(event.data);
						const data = JSON.parse(event.data);
						console.log(data);

						if (data.mode) {
							this.toggle = data.mode;
							console.log(this.toggle);
							if (this.toggle === data.mode) {
								this.firstload = true;
							}
						}
						if (data.brightness) {
							if (typeof data.brightness === "number") {
								this.brightness = data.brightness;
								console.log(this.brightness);
								if (this.brightness === data.brightness) {
									this.secondload = true;
								}
							}
						}
						if (data.color) {
							if (typeof data.color === "number") {
								this.hue = data.color;
								console.log(this.hue);
								if (this.hue === data.color) {
									console.log(this.hue);
									this.thirdload = true;
								}
							}
						}
					};
					this.ws.onclose = () => {
						this.error = true;
						console.log("Disconnected from WS");
					};
					this.ws.onerror = () => {
						this.error = true;
						console.log("Error");
					};
				} catch (e) {
					this.error = true;
					console.log(e);
				}
			},
			sendToWS() {
				if (this.ws.readyState === 1) {
					console.log(
						this.firstload + " " + this.secondload + " " + this.thirdload
					);
					this.ws.send(
						JSON.stringify({
							mode: this.toggle,
							color: this.hue,
							brightness: this.brightness,
						})
					);
				}
			},
		},
	};
</script>