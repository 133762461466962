<style lang="scss" scoped>
	.carousel {
        margin-top: 20px;
		display: flex;
		justify-content: center;
		.carousel-inner {
			position: relative;
			width: 100%;
			overflow: hidden;
		}
	}
</style>
<template>
	<div class="carousel">
		<div class="carousel-inner" v-if="device">
			<template v-for="(device, index) in devices">
                <carousel-item :key="device._id" :device="device" v-show="currentItem === index"></carousel-item>
			</template>
		</div>
	</div>
</template>
<script>
import CarouselItem from "./carouselItem.vue";
	export default {
        data() {
            return {
                currentItem: 0,
				BLEdevices: []
            };
        },
        components: {
            CarouselItem
        },
		props: ["devices"],
		methods: {
			getBLEDevices(){
				if(navigator.bluetooth){
					navigator.bluetooth.requestDevice({
						filters: [{
							namePrefix: 'ESP32'
						}]
					})
					.then(device => {
						this.BLEdevices.push(device);
						console.log(device);
					})
					.catch(error => {
						console.log(error);
					});
				}
			},
			connectDevice(){
				this.BLEdevices[0].gatt.connect()
				.then(server => {
					console.log(server);
				})
				.then(service => {
					console.log(service);
				})
				.then(characteristic => {
					console.log(characteristic);
				})
				.catch(error => {
					console.log(error);
				});
			}
		},
		computed: {
			device() {
			},
		},
	};
</script>