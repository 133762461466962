import Vue from 'vue'
import VueRouter from 'vue-router'
import landing from '@/views/landing.vue'
import me from '@/views/me.vue'
import smartHome from '@/views/smartHome.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    beforeRouteEnter (to, from, next) {
      if (store.state.user == undefined) {
        console.log(store.state.user)
        next()
      } else {
        next('/me')
      }
    },
    path: '/',
    name: 'landing',
    component: landing
  },
  {
    path: '/me',
    name: 'me',
    component: me
  },
  {
    path: '/:id',
    name: 'smartHome',
    component: smartHome

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
