<style lang="scss" scoped>
	a {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: none;
		-webkit-touch-callout: none;
		user-select: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		text-decoration: none;
		color: #000000;

		.smart-home__box {
			padding: 10px;
			border: solid 1px #494949;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 5px;
			cursor: pointer;
			p {
				font-family: "Poppins", sans-serif;
				font-size: 20px;
				font-weight: 500;
			}
		}
		.smart-home__box.active {
			background: rgb(89, 100, 255);
			color: white;
			box-shadow: #232fd12c 0px 7px 10px;
			border: 0;
		}
	}
</style>
<template>
	<router-link
		:to="{ name: 'smartHome', params: { id: smartHome._id } }"
		draggable="true"
	>
		<div class="smart-home__box" :class="{ active: index == 0 }">
			<p>{{ smartHome.name }}</p>
		</div>
	</router-link>
</template>
<script>
	export default {
		data() {
			return {};
		},
		props: ["smartHome", "index"],
		computed: {},
		methods: {
			enterSmartHome() {
				this.$router.push({
					name: "smartHome",
					params: {
						id: this.smartHome.id,
					},
				});
			},
			getDevices() {
				console.log("getting");
				this.$store.dispatch("getDevices");
			},
		},
	};
</script>